import React, { Component } from "react";

export class about extends Component {
  render() {
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}<br /><br /><br /><br /><br />
              <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <p>
                  <br />
                  MCCUBE has been facilitating students in realizing their
                  dreams since 2013 (formerly known as NIT Classes). MCCUBE is
                  formed with a mission to yield the best and make everyone
                  achieve with our specialized teaching methodology. Core policy
                  is Student First i.e. interest of the students is primary. We
                  have facilitated hundreds of students in achieving their goals
                  in various competitive exams. With us students get
                  personalized attention to overcome weaknesses. MCCUBE focuses on
                  diverse segments of education across the learners of multiple
                  age-groups. Designed to shape the young aspirants attitude,
                  aptitude and instill confidence in them as they get ready to
                  take life head-on!
                  <br />
                  <br />
                  We help students crack MBA entrance exams like CAT, XAT, MAT,
                  CMAT etc and to gain admissions into the IIMs and the Top MBA
                  Institutes. We enable individuals to realize their potential
                  and achieve their dreams.
                  <br />
                  <br />
                  All courses are divided into Basic, Foundation and Advanced
                  module. Each student is expected to clear the Basic module
                  before they graduate to the Foundation module and similarly
                  after clearing 'Foundation Module' they graduate to the
                  'Advanced Module'. This helps the students build their
                  foundations and perform with zeal in all their future
                  endeavors. So our methodology is designed in the way we make
                  learning comfortable for everyone. Hence, our motto – From
                  Basics to Best
                </p>
                <h3>Why Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data
                        ? this.props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data
                        ? this.props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default about;
